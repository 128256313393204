import DrawerHandle from '@/components/drawerHandle';
import { SwipeableBottomSheet } from '@/providers/modal/responsiveModal';
import type { Theme } from '@mui/material';
import { Menu, useMediaQuery } from '@mui/material';

export default function ResponsiveMenu( { open, debouncedOpen, Component, props, closeMenu } ) {
	const wide = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( 'sm' ) );
	
	return wide ? debouncedOpen ? (
		<Menu
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			anchorEl={open}
			TransitionProps={{}}
			open={Boolean( open )}
			onClose={( e: MouseEvent ) => {
				e.stopPropagation();
				closeMenu();
			}}
			{...props}>
			{Component && <Component closeMenu={closeMenu}/>}
		</Menu>
	) : null : (
		<SwipeableBottomSheet
			open={Boolean( open )}
			sx={{ display: 'flex', zIndex: 'tooltip', justifyContent: 'center' }}
			onClose={closeMenu}
			onOpen={() => null}
			{...props}>
			<DrawerHandle/>
			{Component && <Component closeMenu={closeMenu}/>}
		</SwipeableBottomSheet>
	);
}
