import { useModal } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import {
	CancelRounded as CancelRoundedIcon,
	CloudDownload as CloudDownloadIcon,
	VisibilityRounded as VisibilityRoundedIcon,
} from '@mui/icons-material';
import type { BadgeProps, SxProps, Theme } from '@mui/material';
import {
	Avatar,
	Badge,
	Box,
	Button,
	ButtonGroup,
	Paper,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledImage from '../styledImage';

const attachmentSize = 86;

/**
 * A function that generates a modal displaying an image and its name,
 * with a clickable icon to close the modal.
 *
 * @param props - The props of the ImageModal component.
 * @param props.src - The source URL of the image.
 * @returns The modal component.
 */
export function ImageModal( { src, whiteBg }: { src: string, whiteBg?: boolean } ): JSX.Element {
	const imageName = GetImageName( src.split( /[#?]/ )[ 0 ].split( '%2F' ).pop() );
	
	return (
		<ResponsiveModalContainer title={imageName} sx={{ p: 0 }}>
			<Box sx={{ p: 3, display: 'flex', alignItems: 'center', bgcolor: whiteBg ? '#ffffff' : 'unset' }}>
				{src.includes( '.pdf' ) ? (
					<iframe
						src={src}
						style={{
							width: '100%',
							// height      : 'calc(100vh - 200px)',
							border      : 0,
							borderRadius: 2,
						}}
					/>
				) : (
					<StyledImage
						src={src}
						alt={imageName}
						sx={{
							height        : 700,
							width         : 700,
							borderRadius  : 2,
							alignSelf     : 'center',
							mx            : 'auto',
							overflow      : 'hidden',
							objectFit     : 'contain',
							imageRendering: '-webkit-optimize-contrast !important',
						}}
					/>
				)}
			</Box>
		</ResponsiveModalContainer>
	);
}

/**
 * A function that returns the name of the image.
 *
 * @param image - The name of the image file.
 * @returns The name of the image.
 */
export function GetImageName( image: string ): string {
	const checkUnderScore = image.split( '_' );
	if ( checkUnderScore.length === 1 ) {
		return decodeURIComponent( image );
	} else {
		return decodeURIComponent( image.slice( 37 ) );
	}
}

/**
 * A function that generates a specific icon depending on the file type.
 *
 * @param src - The source URL of the file.
 * @returns The URL of the specific icon.
 */
function GetImage( src: string ): string {
	const baseUrl = 'https://assets.invoiss.com';
	switch ( src.split( /[#?]/ )[ 0 ].split( '.' ).pop().toLowerCase() ) {
		case 'pdf':
			return `${baseUrl}/pdf-icon.png`;
		case 'csv':
			return `${baseUrl}/csv-icon.png`;
		case 'txt':
			return `${baseUrl}/txt-icon.png`;
		case 'xlsx':
			return `${baseUrl}/xlsx-icon.png`;
		case 'xls':
			return `${baseUrl}/xls-icon.png`;
		case 'docx':
			return `${baseUrl}/docx-icon.png`;
		case 'gif':
			return `${baseUrl}/gif-icon.png`;
		case 'pages':
			return `${baseUrl}/pages-icon.png`;
		case 'numbers':
			return `${baseUrl}/numbers-icon.png`;
		case 'jpeg':
		case 'png':
		case 'jpg':
			return src;
		default:
			return `${baseUrl}/attachment-icon.png`;
	}
}

/**
 * Forces a download action in the browser.
 *
 * @param blob - The URL created from the Blob object.
 * @param filename - The name of the file to be downloaded.
 */
function forceDownload( blob, filename ): void {
	const a = document.createElement( 'a' );
	a.download = filename;
	a.href = blob;
	document.body.appendChild( a );
	a.click();
	a.remove();
}

/**
 * A function that fetches a resource and initiates a download action.
 *
 * @param url - The URL of the resource.
 * @param filename - The name of the file to be downloaded.
 */
export async function downloadResource( url, filename ): Promise<void> {
	const res = await fetch( url, {
		headers: new Headers( { Origin: location.origin } ),
		mode   : 'cors',
	} );
	if ( res.status !== 200 ) throw res.statusText;
	const blob = await res.blob();
	const blobUrl = window.URL.createObjectURL( blob );
	forceDownload( blobUrl, filename );
}

/**
 * A function that fetches a resource with a POST request and initiates a download action.
 *
 * @param url - The URL of the resource.
 * @param filename - The name of the file to be downloaded.
 * @param data - The data to be sent in the POST request.
 */
export async function downloadResourceWithData( url, filename, data ): Promise<void> {
	const res = await fetch( url, {
		headers: new Headers( { Origin: location.origin } ),
		mode   : 'cors',
		method : 'POST',
		body   : JSON.stringify( data ),
		cache  : 'no-cache',
	} );
	
	if ( res.status !== 200 ) throw res.statusText;
	const blob = await res.blob();
	const blobUrl = window.URL.createObjectURL( blob );
	forceDownload( blobUrl, filename );
}

export async function fetchPDFBlob( url ): Promise<Blob> {
	const res = await fetch( url, {
		headers: new Headers( { Origin: location.origin } ),
		mode   : 'cors',
	} );
	if ( !res.ok ) throw new Error( `HTTP error! Status: ${res.status}` );
	const contentType = res.headers.get( 'content-type' );
	if ( !contentType || !contentType.includes( 'application/pdf' ) ) {
		throw new Error( 'Fetched file is not a PDF' );
	}
	return await res.blob();
}

/**
 * A component that displays an image or file icon, with optional deletion and download controls.
 *
 * @param props - The props of the Attachment component.
 * @param props.src - The source URL of the attachment.
 * @param props.onDelete - The function to be called when the attachment is deleted.
 * @param props.removeDownload - The flag to indicate whether the download option should be removed.
 * @param props.handle - An object containing additional properties to customize the component.
 * @param props.imageSX - The styles for the image.
 * @param props.size - The size of the attachment display.
 * @param props.badgeProps - Additional props to pass to the Badge component.
 * @returns The Attachment component.
 */
export default function Attachment( {
	src,
	onDelete,
	removeDownload,
	handle,
	imageSX,
	size = attachmentSize,
	whiteBg,
	...badgeProps
}: {
	src?: string,
	onDelete?: ( url: string ) => void,
	removeDownload?: boolean,
	imageSX?: SxProps,
	size?: number,
	handle?: any,
	whiteBg?: boolean
} & BadgeProps ): JSX.Element | null {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	if ( typeof src !== 'string' ) return null;
	if ( isMobile ) removeDownload = true;
	
	const image = src.split( /[#?]/ )[ 0 ].split( '%2F' ).pop();
	const name = GetImageName( image );
	const isImage = [ 'png', 'jpg', 'jpeg' ].includes( image.split( '.' ).pop().trim().toLowerCase() );
	
	const wrapper = ( children ): JSX.Element => (
		<Badge
			{...handle}
			invisible={!onDelete}
			badgeContent={(
				<Avatar
					sx={{
						':hover' : { opacity: 1 },
						'bgcolor': 'background.paper',
						'height' : 25,
						'width'  : 25,
					}}
					onClick={() => onDelete( src )}>
					<CancelRoundedIcon sx={{ color: 'text.primary', opacity: 0.8 }} fontSize='small'/>
				</Avatar>
			)}
			{...badgeProps}
			sx={{
				'.MuiBadge-badge': { cursor: 'pointer', transition: '.2s' },
				':not(:hover)'   : { '.MuiBadge-badge': { opacity: 0 }, '.download': { opacity: 0 } },
				...badgeProps?.sx,
			}}>
			<Tooltip title={name}>
				<Box sx={{ width: '100%' }}>
					<div>
						{removeDownload || (
							<ButtonGroup
								variant='outlined'
								className='download'
								sx={{
									position      : 'absolute',
									top           : 8,
									right         : 8,
									zIndex        : 2,
									transition    : '.3s',
									backdropFilter: 'blur(9px)',
									bgcolor       : 'background.alpha',
								}}>
								<Tooltip title={t( 'common:download' )} placement='bottom'>
									<Button
										onClick={() => downloadResource( src, name )}>
										<CloudDownloadIcon fontSize='small' color='primary'/>
									</Button>
								</Tooltip>
								{src.includes( '.pdf' ) && (
									<Tooltip title={t( 'commerce:view-pdf' )} placement='bottom'>
										<Button
											onClick={() => window.open( src, '_blank' )}>
											<VisibilityRoundedIcon fontSize='small' color='primary'/>
										</Button>
									</Tooltip>
								)}
							</ButtonGroup>
						)}
						{children}
					</div>
				</Box>
			</Tooltip>
		</Badge>
	);
	
	return wrapper( isImage ? src && (
		// This is when the attachment is an image
		<StyledImage
			src={GetImage( src )}
			alt={name}
			sx={{
				'display'     : 'flex',
				'width'       : size,
				'height'      : size,
				'objectFit'   : 'cover',
				'border'      : 1,
				'borderRadius': 2,
				'borderColor' : 'divider',
				':hover'      : { cursor: handle ? 'grab' : 'pointer' },
				'bgcolor'     : whiteBg ? '#ffffff' : 'unset',
				...imageSX,
			}}
			onClick={( e ) => {
				e.stopPropagation();
				showModal( ImageModal, undefined, { src, whiteBg } );
			}}
		/>
	) : (
		// This is when the attachment is not an image (e.g., a document or other type of file).
		<Paper
			sx={{
				':hover'      : { cursor: handle ? 'grab' : undefined },
				'width'       : size * 2,
				'p'           : 1,
				'borderRadius': 2,
				'position'    : 'relative',
				'overflow'    : 'hidden',
			}}
			onClick={() => {
				if ( src.includes( '.pdf' ) ) {
					if ( isMobile ) {
						showModal( ImageModal, undefined, { src } );
					} else {
						window.open( src, '_blank' );
					}
				}
			}}>
			<Stack
				sx={{ height: `${size} !important` }}
				direction='row'
				alignItems='center'
				spacing={1}>
				<StyledImage
					src={GetImage( src )}
					sx={{
						width : size - 50,
						height: size - 40,
						ml    : 1,
						...imageSX,
					}}
					alt={name}
				/>
				<Typography
					variant='bold'
					sx={{
						textOverflow: 'ellipsis',
						whiteSpace  : 'nowrap',
						overflow    : 'hidden',
					}}>
					{name}
				</Typography>
			</Stack>
		</Paper>
	) );
}
