import { AnimationProps, motion } from 'framer-motion';
import { Fragment, ReactNode } from 'react';
import useGetDeviceInfo from '../../hooks/useGetDeviceInfo';

export default function AnimatedLayoutWrapperFadeInOut( {
	children,
	...props
}: {
	children: ReactNode
} & AnimationProps ) {
	const { isCloverDevice } = useGetDeviceInfo();
	
	if ( isCloverDevice ) {
		return (
			<Fragment>
				{children}
			</Fragment>
		);
	}
	
	// return (
	// 	<Fade in exit timeout={900}>
	// 		<Box>
	// 			{children}
	// 		</Box>
	// 	</Fade>
	// );
	
	return (
		<motion.div
			className='motionDiv'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{
				type     : 'spring',
				duration : .5,
				stiffness: 200,
				damping  : 20,
			}}
			{...props}>
			{children}
		</motion.div>
	);
}

