import { KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon } from '@mui/icons-material';
import { Box, Button, ButtonProps, Container, Stack, SxProps, Typography, useTheme } from '@mui/material';
import React, { isValidElement, ReactNode } from 'react';
import { ActionProps } from '../actions';
import StyledImage from '../styledImage';
import PageLinkComponent from './linkComponent';

export type EmptyPageComponentProps = {
	name: string,
	description: string,
	articleRoute?: string,
	imageSX?: SxProps,
	createAction?: ReactNode | ( {
		name: string
	} & ButtonProps ) | ActionProps,
	customComponent?: ReactNode,
	metric?: number
};

export default function CompanyMetricEmptyPage( {
	name,
	description,
	customComponent,
	articleRoute,
	createAction,
	imageSX,
}: EmptyPageComponentProps ) {
	
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	
	const baseSRC = '/images/placeholder';
	const mode = `${isDarkMode ? 'dark' : 'light'}.png`;
	
	let src: string;
	
	switch ( name ) {
		case 'estimate':
			src = `${baseSRC}/empty-invoice-${mode}`;
			break;
		case 'order':
			src = `${baseSRC}/empty-invoice-${mode}`;
			break;
		case 'invoice':
			src = `${baseSRC}/empty-invoice-${mode}`;
			break;
		case 'purchase':
			src = `${baseSRC}/empty-invoice-${mode}`;
			break;
		case 'item':
			src = `${baseSRC}/placeholder-item-${mode}`;
			break;
		case 'category':
			src = `${baseSRC}/placeholder-item-${mode}`;
			break;
		case 'vendor':
			src = `${baseSRC}/placeholder-vendor-${mode}`;
			break;
		case 'location':
			src = `${baseSRC}/placeholder-location-${mode}`;
			break;
		case 'modifier group':
			src = `${baseSRC}/placeholder-item-${mode}`;
			break;
		case 'inventory':
			src = `${baseSRC}/placeholder-item-${mode}`;
			break;
		case 'client':
			src = `${baseSRC}/placeholder-person-${mode}`;
			break;
		case 'payment':
			src = `${baseSRC}/placeholder-card-${mode}`;
			break;
		case 'houseAccount':
			src = `${baseSRC}/placeholder-houseAccount-${mode}`;
			break;
		case 'campaign':
			src = `${baseSRC}/placeholder-houseAccount-${mode}`;
			break;
		default:
			src = `${baseSRC}/empty-invoice-${mode}`;
			break;
	}
	
	return (
		<Container
			maxWidth='sm'
			sx={{
				display       : 'flex',
				flexDirection : 'column',
				alignItems    : 'center',
				justifyContent: 'center',
				pt            : { xs: 3, sm: 15 },
			}}>
			<Stack spacing={2}>
				<StyledImage
					src={src}
					alt='Empty'
					sx={{
						height: 210,
						width : 170,
						...imageSX,
					}}
				/>
				<Typography variant='h2'>
					Create your first {name} to get started.
				</Typography>
				<Typography color='text.secondary'>
					{description}
				</Typography>
				{articleRoute && (
					<Button
						endIcon={<KeyboardArrowRightRoundedIcon sx={{ mt: .5 }}/>}
						component={PageLinkComponent}
						href={articleRoute}
						variant='text'
						color='primary'
						sx={{
							alignSelf: 'start',
							position : 'relative',
							left     : -4,
						}}>
						Learn more
					</Button>
				)}
				<Stack
					direction='row'
					alignItems='center'
					spacing={1}>
					{createAction ? isValidElement( createAction ) ? (
						<div style={{ alignSelf: 'start' }}>
							{createAction}
						</div>
					) : (
						<Button
							size='large'
							color='primary'
							variant='contained'
							sx={{ alignSelf: 'start' }}
							{...( createAction as ButtonProps )}>
							{typeof createAction === 'object' && true && 'name' in createAction
								? createAction.name
								: null}
						</Button>
					) : null}
				
				</Stack>
				<Box>
					{customComponent}
				</Box>
			</Stack>
		</Container>
	);
}
