export type HapticType = 'success' | 'warning' | 'error' | 'light' | 'selection';

type ReactNativeNotificationType = 'themeChange' | 'haptic' | string;

export function sendReactNativeNotification( {
	type,
	value,
}: {
	type: ReactNativeNotificationType,
	value: string | HapticType
} ) {
	if ( window.ReactNativeWebView ) {
		window.ReactNativeWebView.postMessage( JSON.stringify( { type, value: value } ) );
	}
}

export function isReactNativeWebView() {
	return typeof window !== 'undefined' && ( window.isReactNativeWebView || window.ReactNativeWebView );
}
