import type { AppBarProps, BoxProps, ContainerProps, Theme } from '@mui/material';
import { Box, Container, useMediaQuery } from '@mui/material';
import { Fragment, ReactNode, useRef } from 'react';
import ElevationScroll from '../elevationScroll';
import Loading from '../loading';
import ScrollTop from '../scrollTop';
import AnimatedLayoutWrapperFadeInOut from './animate';
import type { PageBackProps } from './back';
import PageBack from './back';
import CompanyMetricEmptyPage, { EmptyPageComponentProps } from './empty';
import type { PageTitleProps } from './title';
import PageTitle from './title';

export interface PageWrapperProps extends PageTitleProps {
	children?: ReactNode,
	loading?: boolean,
	loadingComponent?: ReactNode,
	animateLayoutChange?: boolean,
	gutterUnderHeader?: number,
	hideElevationScroll?: boolean,
	elevationScrollProps?: AppBarProps,
	containerProps?: ContainerProps,
	elevationScrollChildrenContainerProps?: ContainerProps,
	headerMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
	pageBackProps?: PageBackProps,
	boxProps?: BoxProps,
	hideBack?: boolean,
	modalWrapper?: boolean,
	closeIcon?: ReactNode,
	renderEmptyComponent?: EmptyPageComponentProps
}

export default function PageWrapper( {
	children,
	loading = false,
	loadingComponent,
	animateLayoutChange = true,
	gutterUnderHeader = 10,
	hideElevationScroll,
	elevationScrollProps,
	elevationScrollChildrenContainerProps,
	pageBackProps,
	containerProps,
	headerMaxWidth,
	maxWidth,
	boxProps,
	hideBack,
	closeIcon = null,
	renderEmptyComponent,
	modalWrapper,
	...pageTitleProps
}: PageWrapperProps ) {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const ref = useRef<HTMLDivElement | null>( null );
	
	if ( renderEmptyComponent?.metric === 0 ) {
		return (
			<CompanyMetricEmptyPage {...renderEmptyComponent}/>
		);
	}
	
	if ( loading ) return loadingComponent || <Loading/>;
	
	const headerContent = (
		<Fragment>
			{closeIcon}
			{!hideBack && <PageBack {...pageBackProps}/>}
			<PageTitle {...pageTitleProps}/>
		</Fragment>
	);
	
	const header = isMobile
		? <Box sx={{ px: 2 }}>{headerContent}</Box>
		: (
			<Container
				maxWidth={headerMaxWidth || maxWidth || 'xl'}
				{...elevationScrollChildrenContainerProps}>
				{headerContent}
			</Container>
		);
	
	return (
		<Box
			ref={modalWrapper ? ref : undefined}
			{...boxProps}
			sx={{
				overflow  : modalWrapper ? 'overlay' : undefined,
				transition: modalWrapper ? '.5s all' : undefined,
				...boxProps?.sx,
			}}>
			{!hideElevationScroll ? (
				<ElevationScroll
					targetRef={modalWrapper ? ref : undefined}
					{...elevationScrollProps}>
					{header}
				</ElevationScroll>
			) : header}
			{gutterUnderHeader ? <Box sx={{ bgcolor: 'transparent', height: gutterUnderHeader }}/> : null}
			<Container
				ref={modalWrapper ? ref : undefined}
				maxWidth={maxWidth || 'xl'}
				{...containerProps}
				sx={{
					overflow: 'unset',
					pb      : { xs: 1, sm: 'calc(env(safe-area-inset-bottom) + 40px)' },
					...containerProps?.sx,
				}}>
				{!isMobile && <ScrollTop/>}
				{animateLayoutChange ? (
					<AnimatedLayoutWrapperFadeInOut>
						{children}
					</AnimatedLayoutWrapperFadeInOut>
				) : children}
			</Container>
		</Box>
	);
}
