import { Box, BoxProps } from '@mui/material';

export default function DrawerHandle( { ...props }: BoxProps ) {
	return (
		<Box
			{...props}
			sx={{
				width         : '100%',
				display       : 'flex',
				justifyContent: 'center',
				alignItems    : 'center',
				bgcolor       : 'transparent',
				mt            : 1.5,
				...props?.sx,
			}}>
			<Box
				sx={{
					width       : 35,
					height      : 5,
					bgcolor     : 'divider',
					borderRadius: 2,
				}}
			/>
		</Box>
	);
}
