import { ActionProps } from '@/components/actions';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import { useMenu } from '@/providers/menu';
import { useModal, useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer, ResponsiveModalProps } from '@/providers/modal/responsiveModal';
import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import useEventEffect from './useEventEffect';

function CustomDialog( {
	title,
	message,
	buttonText,
	extraButtonText,
	hideButton,
	onExtraButtonClick,
	resolve,
}: {
	title: string,
	message: ReactNode,
	resolve: ( clicked: boolean ) => void,
	buttonText?: string,
	hideButton?: boolean,
	extraButtonText?: string,
	onExtraButtonClick?: () => void
} ) {
	const { events, closeModal } = useModalControls();
	
	useEventEffect( events, 'close', () => resolve( false ) );
	
	return (
		<ResponsiveModalContainer
			title={title}
			actionItems={[ ...extraButtonText ? [ {
				name       : extraButtonText,
				buttonProps: { variant: 'outlined' },
				onClick    : () => {
					onExtraButtonClick?.();
					resolve( false );
					closeModal();
				},
			} ] as ActionProps[] : [], !hideButton && {
				name       : buttonText || 'Confirm',
				buttonProps: { variant: 'contained', color: 'primary' },
				onClick    : () => {
					resolve( true );
					closeModal();
				},
			} ]}
			onClose={() => {
				resolve( false );
				closeModal();
			}}>
			<Box p={1}>
				{typeof message === 'string' ? <Typography>{message}</Typography> : message}
			</Box>
		</ResponsiveModalContainer>
	);
}

export default function useConfirmDialog() {
	const { showModal } = useModal();
	const { closeMenu } = useMenu();
	
	return ( {
		title,
		message,
		buttonText,
		hideButton,
		extraButtonText,
		closeOnClickOutside,
		onExtraButtonClick,
		modalProps,
	}: {
		title: string,
		message: ReactNode,
		buttonText?: string,
		hideButton?: boolean,
		extraButtonText?: string,
		closeOnClickOutside?: boolean,
		onExtraButtonClick?: () => void,
		modalProps?: Partial<ResponsiveModalProps>
	} ) => {
		closeMenu?.();
		return new Promise( ( resolve ) => {
			showModal( CustomDialog,
				{
					maxWidth           : 'xs',
					onClose            : closeOnClickOutside
						? undefined
						: ( event, reason ) => stayOpen( event, reason ),
					fullPageBottomSheet: false,
					...modalProps,
				}, {
					title,
					message,
					buttonText,
					extraButtonText,
					resolve,
					onExtraButtonClick,
					hideButton,
				} );
		} );
	};
}
