import { useMenu } from '@/providers/menu';
import NestedMenuItem from '@/providers/menu/nestedMenu';
import { ArrowRight as ArrowRightIcon, MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	ButtonGroup,
	ButtonProps,
	CircularProgress,
	Divider,
	ListItemIcon,
	ListItemText,
	ListItemTextProps,
	MenuItemProps,
	MenuList,
	MenuListProps,
	MenuProps,
	Stack,
	Theme,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { Fragment, isValidElement, MouseEvent, ReactElement, ReactNode, useMemo, useState } from 'react';
import AsyncLoadingButton from './form/asyncLoading/asyncLoadingButton';

export type ActionProps = {
	name: ReactNode,
	onClick?: ( event: MouseEvent, closeMenu?: () => void ) => void | string | boolean | Promise<void | any>,
	icon?: ReactNode,
	// endNode is used to display a node at the end of the menu item
	endNode?: ReactNode,
	props?: ButtonProps & MenuItemProps,
	details?: string,
	buttonProps?: ButtonProps,
	menuItemProps?: MenuItemProps & {
		listItemTextProps?: ListItemTextProps
	},
	disabled?: boolean,
	loading?: boolean,
	nestedItems?: ActionProps[],
	nestedMenuProps?: Omit<MenuProps, 'children'>
} | ReactElement;

export type ActionPropsArray = Array<ActionProps | boolean>;

function returnActionMenuItemColor( name, bg, label ) {
	if ( typeof name !== 'string' ) return undefined;
	if ( bg ) {
		if ( toLower( name ) === 'delete' || toLower( name ) === 'remove' ) return 'error.main';
	}
	if ( label ) {
		if ( toLower( name ) === 'delete' || toLower( name ) === 'remove' ) return '#ffffff';
	}
	return undefined;
}

function isActionObject( action: ActionProps ): action is Extract<ActionProps, { name: ReactNode }> {
	return typeof action !== 'undefined' && !isValidElement( action );
}

function RecursiveMenuItems( { items, closeMenu }: { items: ActionPropsArray, closeMenu: () => void } ) {
	const { enqueueSnackbar } = useSnackbar();
	const [ loadingState, setLoadingState ] = useState<{ [ key: string ]: boolean }>( {} );
	
	return (
		<Fragment>
			{items.map( ( action, index ) => {
				if ( !isActionObject( action ) ) return undefined;
				
				const isLoading = loadingState[ action.name as string ] || false;
				
				return (
					<Fragment key={index}>
						<NestedMenuItem
							menuItemChildren={(
								<Fragment>
									{( isLoading || action.icon ) && (
										<ListItemIcon>
											{isLoading ? <CircularProgress size={16}/> : action.icon}
										</ListItemIcon>
									)}
									<ListItemText
										primary={action.name}
										secondary={action.details}
										{...action.menuItemProps?.listItemTextProps}
									/>
									{!Boolean( action.nestedItems?.length ) && action.endNode}
									{action.nestedItems && action.nestedItems?.length > 0 && !action.disabled
										&& <ArrowRightIcon/>}
								</Fragment>
							)}
							disabled={isLoading || action.disabled}
							onClick={async ( e ) => {
								if ( !action.nestedItems ) {
									try {
										setLoadingState( ( prev ) => ( { ...prev, [ action.name as string ]: true } ) );
										await action.onClick?.( e, closeMenu );
										closeMenu();
									} catch ( e ) {
										enqueueSnackbar( String(
											e?.response?.data?.error?.message
											|| e?.response?.data?.message
											|| e?.response?.data
											|| e?.message
											|| e,
										), { variant: 'error' } );
									} finally {
										setLoadingState( ( prev ) => ( { ...prev, [ action.name as string ]: false } ) );
										closeMenu();
									}
								} else {
									e.stopPropagation();
								}
							}}
							{...action.props}
							{...action.menuItemProps}
							sx={{
								'&:hover': {
									bgcolor: returnActionMenuItemColor( action.name, true, false ),
									color  : returnActionMenuItemColor( action.name, false, true ),
									SVG    : { color: returnActionMenuItemColor( action.name, false, true ) },
								},
								...action.menuItemProps?.sx,
							}}
							menuProps={action.nestedMenuProps}>
							{action.nestedItems
								? <RecursiveMenuItems items={action.nestedItems} closeMenu={closeMenu}/>
								: undefined}
						</NestedMenuItem>
						{index !== items.length - 1 && (
							<Divider
								variant='inset'
								component='li'
								sx={{
									marginTop   : '4px !important',
									marginBottom: '4px !important',
								}}
							/>
						)}
					</Fragment>
				);
			} )}
		</Fragment>
	);
}

function RecursiveMenuList( {
	menu,
	closeMenu,
	menuListProps,
}: {
	menu: ActionPropsArray,
	closeMenu: () => void,
	menuListProps?: MenuListProps
} ) {
	
	return (
		<MenuList
			sx={{
				minWidth: { sm: 200 },
				p       : { xs: 1, sm: 0 },
			}}
			{...menuListProps}>
			<RecursiveMenuItems items={menu} closeMenu={closeMenu}/>
		</MenuList>
	);
}

export function RecursiveMenu( {
	items,
	max,
	menuListProps,
	menuButtonContent,
}: {
	items: ActionPropsArray,
	max?: number,
	menuListProps?: MenuListProps,
	menuButtonContent?: {
		icon: any,
		text: string
	}
} ) {
	const theme = useTheme();
	const { showMenu } = useMenu();
	const [ buttons, menu ] = useMemo( () => {
		const filtered = items.filter( Boolean );
		if ( !max || filtered.length <= max ) return [ filtered, [] ];
		
		const buttons = filtered.slice( 0, max - 1 );
		const menu = filtered.slice( max - 1 );
		return [ buttons, menu ];
	}, [ items, max ] );
	
	const isDarkMode = theme.palette.mode === 'dark';
	
	return (
		<Fragment>
			{buttons.map( ( action, index ) => {
				if ( !isActionObject( action ) ) return undefined;
				
				const handleButtonClick = async ( e: MouseEvent ) => {
					e.stopPropagation();
					if ( action.nestedItems && action.nestedItems.length > 0 ) {
						showMenu( ( { closeMenu } ) => (
							<RecursiveMenuList closeMenu={closeMenu} menuListProps={menuListProps} menu={action.nestedItems}/>
						), e.currentTarget );
					} else {
						await action.onClick?.( e );
					}
				};
				
				const loadingButton = !isValidElement( action ) && (
					<AsyncLoadingButton
						variant={isDarkMode ? 'outlined' : 'contained'}
						color={toLower( action.name as string ) === 'delete' ? 'error' : isDarkMode ? 'primary' : 'alpha'}
						startIcon={action.icon}
						endIcon={action.endNode}
						disabled={action.disabled}
						loading={action.loading}
						onClick={handleButtonClick}
						{...action.props}
						{...action.buttonProps}
						sx={{
							width: '100%',
							...action.buttonProps?.sx,
						}}>
						{action.name}
					</AsyncLoadingButton>
				);
				
				return (
					<Fragment key={index}>
						{isValidElement( action )
							? action
							: action.details ? (
								<Tooltip title={action.details}>
									<Box>
										{loadingButton}
									</Box>
								</Tooltip>
							) : loadingButton}
					</Fragment>
				);
			} )}
			{menu.length !== 0 && (
				<Button
					startIcon={menuButtonContent ? menuButtonContent.icon : ''}
					variant={isDarkMode ? 'outlined' : 'contained'}
					color={isDarkMode ? menuButtonContent ? 'inverted' : 'primary' : 'alpha'}
					onClick={( e ) => {
						e.stopPropagation();
						showMenu( ( { closeMenu } ) => (
							<RecursiveMenuList closeMenu={closeMenu} menuListProps={menuListProps} menu={menu}/>
						), e.currentTarget );
					}}>
					{menuButtonContent ? menuButtonContent.text : <MoreHorizIcon/>}
				</Button>
			)}
		</Fragment>
	);
}

export default function Actions( {
	items,
	separated,
	max,
	menuListProps,
	wrapperProps,
}: {
	items: ActionPropsArray,
	separated?: boolean,
	// max number of buttons displayed
	max?: number,
	menuListProps?: MenuListProps,
	wrapperProps?: any
} ) {
	const [ buttons, menu ] = useMemo( () => {
		const filtered = items.filter( Boolean ) ?? [];
		if ( !max || filtered.length <= max ) return [ filtered, [] ];
		
		const buttons = filtered.slice( 0, max - 1 );
		const menu = filtered.slice( max - 1 );
		return [ buttons, menu ];
	}, [ items, max ] );
	
	const Wrapper: any = separated ? Stack : ButtonGroup;
	if ( !buttons.length && !menu.length ) return null;
	return (
		<Wrapper
			direction='row'
			spacing={1}
			{...wrapperProps}
			sx={{
				'.MuiBox-root:not(:first-child)'           : !separated && {
					'.MuiButton-root': {
						borderTopLeftRadius   : 0,
						borderBottomLeftRadius: 0,
						marginLeft            : '-1px',
					},
				},
				'.MuiBox-root:not(:last-child)'            : !separated && {
					'.MuiButton-root': {
						borderTopRightRadius   : 0,
						borderBottomRightRadius: 0,
						borderRightColor       : 'transparent',
					},
				},
				'.MuiButtonGroup-grouped:not(:first-child)': {
					borderTopLeftRadius   : 0,
					borderBottomLeftRadius: 0,
					marginLeft            : '-1px',
				},
				'.MuiButtonGroup-grouped:not(:last-child)' : {
					borderTopRightRadius   : 0,
					borderBottomRightRadius: 0,
					borderRightColor       : 'transparent',
				},
				...wrapperProps?.sx,
			}}>
			<RecursiveMenu items={items} max={max} menuListProps={menuListProps}/>
		</Wrapper>
	);
}

export function PageAction<T>( {
	useActions,
	item,
	max,
	single,
}: {
	useActions: ( item: T, single?: boolean ) => ActionPropsArray,
	item: T,
	max?: number,
	single?: boolean
} ) {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isMedium = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'md' ) );
	const items = useActions( item, single );
	return <Actions separated items={items} max={max || ( isMobile ? 2 : isMedium ? 3 : 5 )}/>;
}
