import { HapticType, sendReactNativeNotification } from '@/helpers/reactNativePostMessage';
import { Box, Button, ButtonProps, CircularProgress, Fade, LinearProgress, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { forwardRef, Fragment, useState } from 'react';

export interface LoadingButtonProps extends ButtonProps {
	loading?: boolean,
	progress?: number,
	onSettled?: () => void,
	hapticType?: HapticType,
	loadingPosition?: 'start' | 'end' | 'center'
}

const AsyncLoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>( ( {
		children,
		loading = false,
		progress,
		onSettled,
		hapticType,
		disabled,
		sx,
		onClick,
		startIcon,
		endIcon,
		loadingPosition = 'center',
		...props
	}, ref ) => {
		const { enqueueSnackbar } = useSnackbar();
		const [ internalLoading, setInternalLoading ] = useState( false );
		const isLoading = loading || internalLoading;
		
		const handleOnClick = async ( arg: any ) => {
			try {
				sendReactNativeNotification( { type: 'haptic', value: hapticType } );
				if ( !loading ) setInternalLoading( true );
				await onClick?.( arg );
			} catch ( e ) {
				enqueueSnackbar( String( e?.response?.data?.error?.message
					|| e?.response?.data?.message
					|| e?.response?.data
					|| e?.details
					|| e?.message
					|| e,
				), { variant: 'error' } );
			} finally {
				setInternalLoading( false );
				onSettled?.();
			}
		};
		
		return (
			<Button
				ref={ref}
				disabled={disabled || isLoading}
				startIcon={isLoading ? undefined : startIcon}
				endIcon={isLoading ? undefined : endIcon}
				sx={{ overflow: 'hidden', ...sx }}
				onClick={handleOnClick}
				{...props}>
				{loadingPosition === 'center' ? (
					// Center loading spinner, children hidden
					<Fragment>
						<Box sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>{children}</Box>
						{isLoading && (
							<Fade in timeout={1000}>
								<CircularProgress
									size={15}
									sx={{
										position: 'absolute',
										margin  : 'auto',
									}}
								/>
							</Fade>
						)}
					</Fragment>
				) : (
					// Start or end loading spinner, children always visible
					<Stack direction='row' alignItems='center'>
						{isLoading && loadingPosition === 'start' && (
							<CircularProgress
								size={15}
								sx={{
									marginRight: 1,
								}}
							/>
						)}
						{children}
						{isLoading && loadingPosition === 'end' && (
							<CircularProgress
								size={15}
								sx={{
									marginLeft: 1,
								}}
							/>
						)}
					</Stack>
				)}
				{isLoading && Boolean( progress ) && (
					<LinearProgress
						variant='determinate'
						sx={{
							position  : 'absolute',
							bottom    : 0,
							width     : '100%',
							height    : '2px',
							background: 'transparent',
						}}
						color='success'
						value={progress}
					/>
				)}
			</Button>
		);
	},
);

export default AsyncLoadingButton;
