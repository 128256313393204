import type { SxProps } from '@mui/material';
import { styled } from '@mui/material';
import type { LinkProps as NextLinkProps } from 'next/dist/client/link';
import NextLink from 'next/link';
import type { AnchorHTMLAttributes } from 'react';
import { forwardRef } from 'react';

const Anchor = styled( NextLink )( {} );

export type PageLinkComponentProps =
	NextLinkProps
	& { sx?: SxProps }
	& Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;

const PageLinkComponent = forwardRef<HTMLAnchorElement, PageLinkComponentProps>( function( {
	href,
	as,
	replace,
	scroll,
	shallow,
	prefetch,
	locale,
	onClick,
	...props
}, ref ) {
	const isExternal = typeof href === 'string' && ( href.indexOf( 'http' ) === 0 || href.indexOf( 'mailto:' ) === 0 );
	
	if ( isExternal ) {
		return (
			<Anchor
				ref={ref}
				href={href}
				onClick={onClick ? async ( e ) => {
					try {
						await onClick( e );
					} catch {
						e.preventDefault();
					}
				} : undefined}
				{...props}
			/>
		);
	} else {
		return (
			<Anchor
				ref={ref}
				passHref
				href={href}
				prefetch={prefetch}
				as={as as any}
				replace={replace}
				scroll={scroll}
				shallow={shallow}
				locale={locale}
				onClick={onClick ? async ( e ) => {
					try {
						await onClick( e );
					} catch {
						e.preventDefault();
					}
				} : undefined}
				{...props}
			/>
		);
	}
} );
export default PageLinkComponent;
