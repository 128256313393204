import { Device, DeviceInfo } from '@capacitor/device';
import { useState } from 'react';
import { useAsyncEffect } from 'rooks';

type DeviceInfoType = {
	data: DeviceInfo | null,
	isCloverDevice: boolean,
	isFetchingDeviceInfo: boolean
};

export default function useGetDeviceInfo(): DeviceInfoType {
	const [ data, setData ] = useState<DeviceInfo | null>( null );
	const [ isFetchingDeviceInfo, setIsFetchingDeviceInfo ] = useState<boolean>( true );
	const isCloverDevice = [
		'C300',
		'C301',
		'C302',
		'C302U',
		'C303',
		'C304',
		'C305',
		'C400',
		'C401',
		'C402',
		'C403',
		'C404',
		'C405',
		'C500',
		'C501',
		'C502',
		'C503',
		'C504',
		'C505' ].includes( data?.model || '' );
	
	useAsyncEffect( async () => {
		setIsFetchingDeviceInfo( true );
		const info: DeviceInfo = await Device.getInfo();
		setData( info );
		setIsFetchingDeviceInfo( false );
	}, [] );
	
	return {
		data,
		isCloverDevice,
		isFetchingDeviceInfo,
	};
}
