import { MenuProps, SwipeableDrawerProps } from '@mui/material';
import { ComponentType, createContext, useContext, useState } from 'react';
import { useDebouncedValue } from 'rooks';
import ResponsiveMenu from './responsiveMenu';

type C = {
	showMenu: (
		Component: ComponentType<{ closeMenu: () => void }>,
		element: Element | string,
		props?: Partial<Omit<MenuProps & SwipeableDrawerProps, 'open' | 'onClose' | 'onOpen'>>,
	) => void,
	closeMenu: () => void
};

const MenuContext = createContext<C>( {
	showMenu : () => null,
	closeMenu: () => null,
} );
MenuContext.displayName = 'Menu';

export default function MenuProvider( { children } ) {
	const [ open, setOpen ] = useState<Element>( null );
	const [ props, setProps ] = useState<Partial<Omit<MenuProps & SwipeableDrawerProps, 'open' | 'onClose' | 'onOpen'>>>();
	const [ Component, setComponent ] = useState<ComponentType<{ closeMenu: () => void }>>();
	const [ debouncedOpen, immediatelySetDebouncedOpen ] = useDebouncedValue( Boolean( open ), 500 );
	
	return (
		<MenuContext.Provider
			value={{
				showMenu : ( Component, element, props ) => {
					setComponent( () => Component );
					setOpen( typeof element === 'string' ? document.querySelector( element ) : element );
					setProps( props );
					immediatelySetDebouncedOpen( true );
				},
				closeMenu: () => setOpen( null ),
			}}>
			<ResponsiveMenu
				open={open}
				debouncedOpen={debouncedOpen}
				Component={Component}
				props={props}
				closeMenu={() => setOpen( null )}
			/>
			{children}
		</MenuContext.Provider>
	);
}

export function useMenu() {
	return useContext( MenuContext );
}

export function withMenu( Component ) {
	return ( props ) => (
		<MenuContext.Consumer>
			{( showMenu: C ) => <Component showMenu={showMenu} {...props}/>}
		</MenuContext.Consumer>
	);
}
