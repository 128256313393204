import { Box, Menu, MenuItem, MenuItemProps, MenuProps, useTheme } from '@mui/material';
import React, {
	forwardRef,
	HTMLAttributes,
	ReactNode,
	RefAttributes,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

export interface NestedMenuItemProps extends MenuItemProps {
	ContainerProps?: HTMLAttributes<HTMLElement> & RefAttributes<HTMLElement | null>,
	menuProps?: Omit<MenuProps, 'children'>,
	menuItemChildren?: ReactNode
}

const NestedMenuItem = forwardRef<
	HTMLLIElement | null,
	NestedMenuItemProps
>( function NestedMenuItem( {
	menuItemChildren,
	children,
	menuProps,
	ContainerProps: ContainerPropsProp = {},
	...props
}, ref ) {
	const { spacing } = useTheme();
	
	const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;
	
	const menuItemRef = useRef<HTMLLIElement>( null );
	useImperativeHandle( ref, () => menuItemRef.current );
	
	const containerRef = useRef<HTMLDivElement>( null );
	useImperativeHandle( containerRefProp, () => containerRef.current );
	
	const menuContainerRef = useRef<HTMLDivElement>( null );
	
	const [ isSubMenuOpen, setIsSubMenuOpen ] = useState( false );
	
	return (
		<Box
			{...ContainerProps}
			ref={containerRef}
			sx={{
				...children && {
					mx: spacing( -0.5 ),
					px: spacing( 0.5 ),
				},
			}}
			onFocus={( event: React.FocusEvent<HTMLElement> ) => {
				if ( event.target === containerRef.current ) {
					setIsSubMenuOpen( true );
				}
				
				if ( ContainerProps?.onFocus ) {
					ContainerProps.onFocus( event );
				}
			}}
			onMouseEnter={( event: React.MouseEvent<HTMLElement> ) => {
				if ( React.Children.count( children ) === 0 ) return;
				if ( props.disabled ) return;
				setIsSubMenuOpen( true );
				
				if ( ContainerProps?.onMouseEnter ) {
					ContainerProps.onMouseEnter( event );
				}
			}}
			onMouseLeave={( event: React.MouseEvent<HTMLElement> ) => {
				setIsSubMenuOpen( false );
				
				if ( ContainerProps?.onMouseLeave ) {
					ContainerProps.onMouseLeave( event );
				}
			}}>
			<MenuItem {...props} ref={menuItemRef}>
				{menuItemChildren}
			</MenuItem>
			{children && (
				<Menu
					disableAutoFocus
					disableEnforceFocus
					style={{ pointerEvents: 'none' }}
					anchorEl={menuItemRef.current}
					anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={isSubMenuOpen}
					autoFocus={false}
					MenuListProps={{ sx: { minWidth: 200 } }}
					onClose={() => {
						setIsSubMenuOpen( false );
					}}
					{...menuProps}>
					<Box ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
						{children}
					</Box>
				</Menu>
			)}
		</Box>
	);
} );

export default NestedMenuItem;
