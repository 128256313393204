import { fetchPDFBlob } from '@/components/fileUploading/attachment';
import { getInvoiceBalance } from '@/pages/dashboard/commerce/multiPayments/utils';
import { Order } from '@/types/schema';
import saveAs from 'file-saver';
import zip from 'jszip';
import { toLower } from 'lodash-es';

export const getHATotalFromPayments = ( invoice: Order ): {
	paidAmount: number,
	refundedAmount: number
} => invoice.payments.reduce( ( totals, payment ) => {
	
	if ( [ 'invoiss', 'haps' ].includes( toLower( payment.type ) ) && payment.status === 'PAID' ) {
		totals = { ...totals, paidAmount: totals.paidAmount + ( payment.amount + ( payment.tip || 0 ) ) };
	}
	if ( payment.status === 'REFUNDED' ) {
		totals = { ...totals, refundedAmount: totals.refundedAmount + ( payment.refundedAmount || 0 ) };
		
	}
	return totals;
}, { paidAmount: 0, refundedAmount: 0 } );

export const getHAGenerateInvoicesTotalAmount = ( invoices: Order[] ) => invoices.reduce( ( sum, invoice ) => {
	sum += getInvoiceBalance( invoice );
	return sum;
}, 0 );

/**
 * Downloads a group of PDFs based on the selected invoices and type.
 *
 * @param {Order[]} selectedInvoices - The array of selected invoices.
 * @param {'invoice' | 'order'} type - The type of PDF to download.
 * @returns {Promise<void>} - A Promise that resolves once the PDFs are downloaded.
 */
export async function groupPdfDownload( selectedInvoices: Order[], type: 'invoice' | 'order' ) {
	const zz = new zip();
	const pdfPromises = selectedInvoices.map( async ( invoice, index ) => {
		const timezone = invoice.companyLocation?.timezone ?? Intl.DateTimeFormat()
			.resolvedOptions().timeZone ?? 'America/Chicago';
		
		const externalPdfUrl = `${process.env.NEXT_PUBLIC_SITE_URL}/api/preview/pdf/${invoice.id}/${type}?timezone=${timezone}`;
		
		const pdfBlob = await fetchPDFBlob( `/api/proxy?url=${encodeURIComponent( externalPdfUrl )}` );
		zz.file( `${type}-${index}.pdf`, pdfBlob );
	} );
	await Promise.all( pdfPromises );
	
	const blob = await zz.generateAsync( { type: 'blob' } );
	saveAs( blob, `${type}s.zip`, { autoBom: false } );
};

