import type { ListItemProps, ListItemTextProps, Theme } from '@mui/material';
import { Box, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import type { ReactNode } from 'react';
import Actions, { ActionPropsArray } from '../actions';

export interface PageTitleProps extends ListItemTextProps {
	actions?: ActionPropsArray | ReactNode,
	listItemProps?: ListItemProps,
	children?: ReactNode,
	divider?: boolean,
	icon?: ReactNode,
	max?: number
}

export default function PageTitle( {
	actions,
	listItemProps,
	divider = false,
	children,
	icon,
	max,
	...props
}: PageTitleProps ) {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	return (
		<ListItem
			component='div'
			divider={divider}
			{...listItemProps as any}
			sx={{ px: 0, ...listItemProps?.sx }}>
			{icon && <ListItemIcon sx={{ mt: .5 }}>{icon}</ListItemIcon>}
			<ListItemText
				sx={{ whiteSpace: { xs: 'nowrap' }, textAlign: { xs: 'left' } }}
				primaryTypographyProps={{ variant: isMobile ? 'h3' : 'h1' }}
				secondaryTypographyProps={{ component: 'div' as any, fontSize: '1.6rem !important' }}
				{...props}
			/>
			<Box>
				{Array.isArray( actions ) ? (
					<Box sx={{ '.MuiButton-root': { height: '30px', alignSelf: 'center' } }}>
						<Actions separated items={actions} max={max || 3}/>
					</Box>
				) : actions}
			</Box>
		</ListItem>
	);
}
