import { axiosClient } from '@/data';
import axios from 'axios';

export type CompanyBillingDataType =
	'invoicePayment'
	| 'payOnClover'
	| 'swipeCard'
	| 'HACron'
	| 'HAOneBillStatement'
	| 'onlineStoreOrders'
	| 'partialPayments'
	| 'editOnClover'
	| 'bankAccount'
	| 'mergeHA'
	| 'mergeCommerces'
	| 'textMessaging'
	| 'drivers'
	| null;

export default async function postCloverMeteredBilling(
	{
		orderId,
		gatewayId,
		eventType,
		count,
		key,
		staffId,
		clientId,
		entityCount,
	}: {
		orderId?: string,
		gatewayId?: string,
		eventType?: string,
		count?: number,
		key?: CompanyBillingDataType,
		staffId?: string,
		clientId?: string,
		entityCount?: number
	} ) {
	if ( !gatewayId ) return;
	
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postMeteredBilling`, {
		orderId,
		gatewayId,
		count,
		key,
		eventType,
		staffId,
		clientId,
		entityCount,
	} );
	
}
