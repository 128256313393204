import type { DependencyList } from 'react';
import { useEffect } from 'react';

export default function useEventEffect(
	event: { on: Function, off: Function }
	| { addListener: Function, removeListener: Function }
	| { addEventListener: Function, removeEventListener: Function },
	eventName: string | symbol | keyof WindowEventMap,
	listener: ( ...args: any[] ) => void,
	deps?: DependencyList,
	callOnce?: boolean,
) {
	useEffect( () => {
		// @ts-ignore
		const add = event.on || event.addListener || event.addEventListener;
		// @ts-ignore
		const remove = event.off || event.removeListener || event.removeEventListener;
		
		if ( callOnce ) listener();
		add.bind( event )( eventName, listener );
		return () => {
			remove.bind( event )( eventName, listener );
		};
	}, deps );
}
